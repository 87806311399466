<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :times="headTime"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime">
      <img src="@/assets/speaial_activity_img/image/ninetitle.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex-1">
      <div class="mainData flex flex-d ai-c" v-for="(item,index) in nineData" :key="index">
        <div class="rankind-item">
          <div class="title">{{item.category}}</div>
          <div class="ranking-content flex jc-c" v-for="(itemChild,indexChild) in item.topCategoryBrand" :key="indexChild">
            <div class="ranking-order">
              <img v-if="indexChild<3" :src="imgIconRanking[indexChild]" alt="" class="hotIcon">
              <span class="level" v-else>{{ (indexChild + 1).toString().padStart(2, '0') }}</span>
            </div>
            <span class="ranking-name" @mouseenter="hoverName" @mouseleave='initNameIndexShow=null' :data-index="index+'-'+indexChild">{{itemChild.brand}}</span>
            <span class="init-name" v-show="initNameIndexShow==index+'-'+indexChild">{{itemChild.brand}}</span>
          </div>
        </div>
      </div>
    </div>
    <Annotation />
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Annotation from '@/components/Big/Annotation'
import TitleAndTime from '@/components/Big/TitleAndTime'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  components: {
    Headers,
    Annotation,
    TitleAndTime,
  },
  data() {
    return {
      initNameIndexShow: null,
      nineData: [],
      dataTime: '',
      headTime: '',
      imgIconRanking: [
        require('@/assets/speaial_activity_img/diyiming.png'),
        require('@/assets/speaial_activity_img/dierming.png'),
        require('@/assets/speaial_activity_img/disanming.png'),
      ],
    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.init(newValue)
      },
    },
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(value) {
      if (value.length == 0) return
      // console.log('楼层9 zn', value)
      this.nineData = []
      // let list = []
      // const firstKey = Object.keys(value)[0]
      this.dataTime = value[0].topCategoryBrand[0].time + '数据时段'
      // const keys = Object.keys(value).sort()
      // for (var key of keys) {
      //   list.push({
      //     title: key,
      //     data: value[key].slice(0, 10),
      //   })
      // }
      // this.nineData = list.slice(0, 8)
      this.nineData = value.map((res) => {
        return {
          category: res.category,
          topCategoryBrand: res.topCategoryBrand.slice(0, 10),
        }
      })
    },
    hoverName(e) {
      if (e.target.scrollWidth > e.target.offsetWidth) {
        this.initNameIndexShow = e.target.getAttribute('data-index')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
